/* eslint-disable import/no-duplicates */
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

// @mui
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'

import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

// types
// components
import { useSnackbar } from 'src/components/snackbar'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import axiosInstance from 'src/utils/axios'
import { Grid } from '@mui/material'
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete'
import { useQueryClient } from '@tanstack/react-query'

type Props = {
  open: boolean
  onClose: VoidFunction
  onRefleshTable: VoidFunction
  currentUser?: { _id: string; name?: string }
}

export default function EditBudget({ onRefleshTable, currentUser, open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar()

  const defaultValues = useMemo(
    () => ({
      ...currentUser,
    }),
    [currentUser],
  )

  const methods = useForm<any>({ defaultValues })
  const queryClient = useQueryClient()
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        await axiosInstance.put(`/budgets/${currentUser?._id}`, {
          name: data.name,
          status: data.status?.value ?? undefined,
        })

        queryClient.invalidateQueries({ queryKey: ['budgets'] })

        reset()
        onClose()
        onRefleshTable()
        enqueueSnackbar('Alteração realizada com sucesso!')
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro na requisição!', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, onClose, reset, currentUser, onRefleshTable, queryClient],
  )

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods}>
        <DialogTitle>Editar orçamento</DialogTitle>

        <DialogContent>
          <Box padding={1}>
            <Grid item xs={6} spacing={1}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <RHFTextField name='name' label='Nome' />
                </Grid>
                <Grid item xs={12}>
                  <RHFAutocomplete
                    name='status'
                    label='Status'
                    // @ts-ignore
                    isOptionEqualToValue={(option, value) => option.value === value.value} // Verifica se a opção corresponde ao valor
                    options={[
                      { label: 'Enviado', value: 'NEW' },
                      { label: '1 - Follow Up(Em Negociação)', value: 'IN_PROGRESS1' },
                      { label: '2 - Follow Up(Em Negociação)', value: 'IN_PROGRESS2' },
                      { label: '3 - Follow Up(Em Negociação)', value: 'IN_PROGRESS3' },
                      { label: '1 - Follow Up(Sem retorno)', value: 'IN_PROGRESS1_NO_CONTACT' },
                      { label: '2 - Follow Up(Sem retorno)', value: 'IN_PROGRESS2_NO_CONTACT' },
                      { label: '3 - Follow Up(Sem retorno)', value: 'IN_PROGRESS3_NO_CONTACT' },
                      { label: 'Perdido(Não quis fechar com a Routes)', value: 'LOST' },
                      { label: 'Mensagem Final sem retorno', value: 'NO_CONTACT' },
                      { label: 'Fase final de fechamento', value: 'FINAL' },
                      { label: '⁠Não vem mais a Foz', value: 'NOT_IN_CITY' },
                      { label: 'Vendido', value: 'CLOSED' },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={onClose} color='error'>
            Cancelar
          </Button>

          <LoadingButton
            type='button'
            onClick={handleSubmit(onSubmit)}
            variant='contained'
            loading={isSubmitting}
          >
            Adicionar
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}
