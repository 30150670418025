import 'src/locales/i18n'

// scroll bar
import 'simplebar-react/dist/simplebar.min.css'

// lightbox
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/captions.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'

import 'react-lazy-load-image-component/src/effects/blur.css'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider as LocPro } from '@mui/x-date-pickers-pro'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import LogRocket from 'logrocket'
import { SettingsProvider } from './components/settings'
import ThemeProvider from './theme'
import MotionLazy from './components/animate/motion-lazy'
import { useScrollToTop } from './hooks/use-scroll-to-top'
import Router from './routes/sections'
import { AuthProvider } from './auth/context/jwt'
import { SnackbarProvider } from './components/snackbar'

const queryClient = new QueryClient()

function App() {
  useScrollToTop()

  LogRocket.init('cd7maj/routes-iguassu')

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <LocPro dateAdapter={AdapterDateFns}>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <QueryClientProvider client={queryClient}>
            <ThemeProvider>
              <MotionLazy>
                <SnackbarProvider>
                  <AuthProvider>
                    <Router />
                  </AuthProvider>
                </SnackbarProvider>
              </MotionLazy>
            </ThemeProvider>
          </QueryClientProvider>
        </SettingsProvider>
      </LocPro>
    </LocalizationProvider>
  )
}

export default App
