import { Autocomplete, FormControl, TextField } from '@mui/material'
import { SyntheticEvent, useCallback, useState } from 'react'
import { OptionType } from 'src/components/hook-form/rhf-autocomplete'

export const SelectStatus = ({ onFilters, filters }: { onFilters: any; filters: any }) => {
  const [query, setQuery] = useState('')

  const statusOptions = [
    { label: 'Enviado', value: 'NEW' },
    { label: '1 - Follow Up(Em Negociação)', value: 'IN_PROGRESS1' },
    { label: '2 - Follow Up(Em Negociação)', value: 'IN_PROGRESS2' },
    { label: '3 - Follow Up(Em Negociação)', value: 'IN_PROGRESS3' },
    { label: '1 - Follow Up(Sem retorno)', value: 'IN_PROGRESS1_NO_CONTACT' },
    { label: '2 - Follow Up(Sem retorno)', value: 'IN_PROGRESS2_NO_CONTACT' },
    { label: '3 - Follow Up(Sem retorno)', value: 'IN_PROGRESS3_NO_CONTACT' },
    { label: '⁠Não vem mais a Foz', value: 'NOT_IN_CITY' },
    { label: 'Perdido(Não quis fechar com a Routes)', value: 'LOST' },
    { label: 'Mensagem Final sem retorno', value: 'NO_CONTACT' },
    { label: 'Fase final de fechamento', value: 'FINAL' },
    { label: 'Vendido', value: 'CLOSED' },
  ]

  const handleFilterService = useCallback(
    (event: SyntheticEvent<Element, Event>, selectedValues: any[]) => {
      onFilters(
        'status',
        selectedValues.map((item) => item.value),
      )
    },
    [onFilters],
  )

  return (
    <FormControl
      sx={{
        flexShrink: 0,
        width: '100%',
      }}
    >
      <Autocomplete
        id='status-list'
        multiple
        options={statusOptions}
        isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
        getOptionLabel={(option: OptionType | string) => (option as OptionType)?.label}
        onChange={handleFilterService}
        value={filters.status}
        loading={false}
        inputValue={query}
        onInputChange={(event, newInputValue) => {
          setQuery(newInputValue)
        }}
        renderInput={(params) => <TextField label='Status' {...params} />}
      />
    </FormControl>
  )
}
